@import '../../scss/variable';

.layout {
  flex-grow: 1;
  &__menuButton {
    margin-right: 2rem;
    & > * {
      color: $color-yellow-lighter;
    }
  }
  &__menus {
    display: flex;
    flex-grow: 1;
    gap: 1rem;
    margin-inline-start: 2rem;
    & > a {
      text-decoration: none;
      color: $color-white;
      font-size: 1.2rem;
      font-weight: bold;
      &:hover {
        color: $color-grey-7;
      }
    }
  }
  &__container {
    padding: 2rem;
  }
}
