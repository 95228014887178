@import '../../scss/variable';
@import '../../scss/mixins';

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: $color-blue-lighter;
  &__card {
    width: 40rem;
    &.MuiPaper-rounded  {
        border-radius: 2rem;
    }
    &-title {
      text-align: center;
      color: $color-blue-dark;
      margin-bottom: 1rem;
    }

    @include respond(phone) {
      width: 90%;
    }
  }
  &__container {
    & > .MuiGrid-item {
      margin-block: 1rem;
    }
  }
  &__action {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
