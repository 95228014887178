@import '../../../scss/variable';
@import '../../../scss/mixins';

.drawinglist {
  &__header {
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
  }
  &__drawing {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 2rem;
    &-item {
      display: flex;
      height: 15rem;
      flex: 0 1 calc(25% - 1rem);
      background-color: $color-white;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      color: $color-blue-sec;
      cursor: pointer;
      text-align: center;
      border-radius: 1rem;
      border: 1px solid $color-blue-sec;

      @include respond(tab-port) {
        flex: 0 1 calc(50% - 1rem);
      }
      @include respond(phone) {
        flex: 0 1 100%;
      }

      svg {
        display: none;
      }

      &:hover {
        background-color: $color-blue-sec;
        color: $color-white;
        svg {
          display: block;
        }
        span {
          display: none;
        }
      }
    }
  }
}
