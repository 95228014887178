$color-green-main: #00d565;
$color-green-dark: #109b52;
$color-green-sec: #00d565;
$color-green-light: #4de294;
$color-green-lighter: #9becc1;

$color-yellow-dark: #ffd33b;
$color-yellow-sec: #e28a05;
$color-yellow-light: #ffd33b;
$color-yellow-lighter: #ffdf71;
$color-yellow-main: #ffedae;

$color-blue-dark: #1fcbe3;
$color-blue-sec: #02a3c7;
$color-blue-light: #1fcbe3;
$color-blue-lighter: #69dbeb;
$color-blue-main: #c4ecf1;

$color-orange-dark: #fd7c02;
$color-orange-sec: #d25f0c;
$color-orange-light: #fd7c02;
$color-orange-lighter: #fd7c02;
$color-orange-main: #ffc289;

$color-black-dark: #2e2b2f;
$color-grey-1: #2e2b2f;
$color-grey-2: #49444a;
$color-grey-3: #5d5960;
$color-grey-4: #6e6871;
$color-grey-5: #878289;
$color-grey-6: #b3adb6;
$color-grey-7: #b3adb6;

$color-white: #ffffff;
