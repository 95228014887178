@import '../../../scss/variable';
@import '../../../scss/mixins';

.drawing {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  &__pic {
    display: flex;
    height: 15rem;
    flex: 0 1 calc(33% - 1rem);
    background-color: $color-white;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: $color-blue-sec;
    cursor: pointer;
    text-align: center;
    border-radius: 1rem;
    border: 1px solid $color-blue-sec;

    @include respond(tab-port) {
      flex: 0 1 calc(50% - 1rem);
    }
    @include respond(phone) {
      flex: 0 1 100%;
    }
  }
}

#fileinput {
  display: none;
}
