@import './variable';
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  //font-size: 62.5%;

  font-size: 75%;
  @include respond(tab-land) {
    font-size: 56.25%;
  }

  @include respond(tab-port) {
    font-size: 50%;
  }

  @include respond(big-desktop) {
    font-size: 75%;
  }
}
body {
  box-sizing: border-box;
}

.snackbar {
  &__success {
    & > .MuiPaper-root {
      background-color: $color-green-dark;
    }
  }
  &__error {
    & > .MuiPaper-root {
      background-color: $color-orange-sec;
    }
  }
}
iframe {
  display: none;
}
