@import '../../scss/mixins';

.commonFilter {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 2rem 1rem;
  margin: '0 1rem 1rem 0';
  @include respond(tab-port) {
    flex-direction: column;
  }
  & > div {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
}
